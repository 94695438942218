export const state = () => ({
  inplaymarkets: [],
  upcomingMarkets: [],
  inplaymarketsCount: 0,
  upcomingMarketsCount: 0,
  loading: false,
  error: {},
  showElectionData: 0,
  upcomingFlag: null,
  callAPIs: false
})

export const mutations = {
  set_markets(state, data) {
    if (data) {
      const matchIds = []
      const eventIds = []

      data.forEach((e) => {
        if (e.markets && e.markets.length > 0) {
          e.markets.forEach((market) => {
            if (
              market &&
              market.markets &&
              market.markets.length > 0 &&
              market.markets[0].inPlayStatus === 'Open'
            ) {
              market.eventId && eventIds.push(market.eventId)
            }
            if (
              market.inPlayStatus === 'Open' &&
              market.betfairStatus !== 'SUSPENDED'
            ) {
              market.betfairId && matchIds.push(market.betfairId)
            }
          })
        }
      })
      if (matchIds.length > 0) {
        this.commit('socketInplayRate/SET_IDS', matchIds)
        this.commit('socketBookmaker/SET_IDS', matchIds)
      }
      if (eventIds.length > 0) {
        const joinRoomName = eventIds.map((eventId) => {
          return `uof_main_market_${eventId}`
        })
        this.commit('socketInplayRate/SET_EVENT_IDS', joinRoomName)
      }
      if (data && data.length) {
        const newEl = data.filter((game) => game.name === 'Election')
        if (newEl && newEl.length) {
          localStorage.setItem('showElectionData', 1)
          state.showElectionData = 1
        } else {
          state.showElectionData = 0
          localStorage.setItem('showElectionData', 0)
        }
      }
      if (this.$auth.loggedIn && this.$auth.user) {
        if (!this.$auth.user.horseRacing) {
          data = data.filter((game) => game.name !== 'Horse Racing')
        }
        if (!this.$auth.user.greyhoundRacing) {
          data = data.filter((game) => game.name !== 'Greyhound Racing')
        }
        if (!this.$auth.user.cricket) {
          data = data.filter((game) => game.name !== 'Cricket')
        }
        if (!this.$auth.user.soccer) {
          data = data.filter((game) => game.name !== 'Soccer')
        }
        if (!this.$auth.user.tennis) {
          data = data.filter((game) => game.name !== 'Tennis')
        }
        if (!this.$auth.user.sportsbook) {
          data = data.filter(
            (game) =>
              game.name !== 'Horse Racing' &&
              game.name !== 'Greyhound Racing' &&
              game.name !== 'Election' &&
              game.name !== 'Cricket' &&
              game.name !== 'Soccer' &&
              game.name !== 'Tennis'
          )
        }
      }
      state.inplaymarkets = data
      state.inplaymarketsCount = state.inplaymarkets.reduce(calcCount, 0)
      state.upcomingMarketsCount = state.upcomingMarkets.reduce(calcCount, 0)
    }
  },
  set_upcoming_markets(state, data) {
    if (data) {
      const matchIds = []
      const eventIds = []

      data.forEach((e) => {
        if (e.markets && e.markets.length > 0) {
          e.markets.forEach((market) => {
            market.betfairId && matchIds.push(market.betfairId)
            market.eventId && eventIds.push(market.eventId)
          })
        }
      })
      if (matchIds.length > 0) {
        this.commit('socketRate/SET_IDS', matchIds)
        this.commit('socketBookmaker/SET_IDS', matchIds)
      }
      if (eventIds.length > 0) {
        const joinRoomName = eventIds.map((eventId) => {
          return `uof_main_market_${eventId}`
        })
        this.commit('socketRate/SET_EVENT_IDS', joinRoomName)
      }
      if (this.$auth.loggedIn && this.$auth.user) {
        if (!this.$auth.user.horseRacing) {
          data = data.filter((game) => game.name !== 'Horse Racing')
        }
        if (!this.$auth.user.greyhoundRacing) {
          data = data.filter((game) => game.name !== 'Greyhound Racing')
        }
        if (!this.$auth.user.cricket) {
          data = data.filter((game) => game.name !== 'Cricket')
        }
        if (!this.$auth.user.soccer) {
          data = data.filter((game) => game.name !== 'Soccer')
        }
        if (!this.$auth.user.tennis) {
          data = data.filter((game) => game.name !== 'Tennis')
        }
        if (!this.$auth.user.sportsbook) {
          data = data.filter(
            (game) =>
              game.name !== 'Horse Racing' &&
              game.name !== 'Greyhound Racing' &&
              game.name !== 'Election' &&
              game.name !== 'Cricket' &&
              game.name !== 'Soccer' &&
              game.name !== 'Tennis'
          )
        }
      }
      state.upcomingMarkets = data
      state.inplaymarketsCount = state.inplaymarkets.reduce(calcCount, 0)
      state.upcomingMarketsCount = state.upcomingMarkets.reduce(calcCount, 0)
    }
  },
  set_empty_markets(state) {
    state.upcomingMarkets = []
    state.markets = []
  },
  set_error(state, data) {
    state.error = data
  },
  set_loading(state, data) {
    state.loading = data
  },
  set_election_data(state, value) {
    state.showElectionData = value
  },
  set_upcoming_flag(state, value) {
    state.upcomingFlag = value
  },
  set_call_apis(state, value) {
    state.callAPIs = value
  }
}
export const actions = {
  async get(
    { commit },
    { userId, socketData, upComing = false, loading = true }
  ) {
    try {
      commit('set_loading', loading)
      commit('set_empty_markets')
      if (!socketData) {
        const { data } = await this.$axios({
          method: 'post',
          baseURL: process.env.SPORTSBOOK_API_URL_MARKET,
          url: 'category/newGetInPlayMarkets',
          data: {
            _id: userId,
            _accessToken: this.$auth.getToken('customLocal'),
            upComing
          }
        })
        if (upComing) {
          commit('set_upcoming_markets', data.data)
        } else {
          commit('set_markets', data.data)
        }
      } else if (socketData && upComing) {
        // console.log('set_upcoming_markets ............', socketData)
        commit('set_upcoming_markets', socketData)
      } else {
        // console.log('set_markets ............', socketData)
        commit('set_markets', socketData)
      }
      commit('set_loading', false)
    } catch (error) {
      commit('set_error', error)
      commit('set_loading', false)
    }
  }
}

function calcCount(sum, item) {
  sum += item.markets.length
  return sum
}
