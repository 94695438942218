import isEmpty from 'lodash/isEmpty'
export const state = () => ({
  matchIds: [],
  bettingType: '',
  market: [],
  runner: [],
  allRunners: [],
  placeBetJSON: {},
  odds: 0,
  runs: 0,
  position: 0,
  oddsIndex: 0,
  type: '',
  isCashOut: false,
  loading: false,
  error: {},
  success: '',
  warning: '',
  urlMap: {
    default: 'Betfair/placePlayerBetNew',
    fancy: 'Betfair/placePlayerBetFancyCustom',
    bookmaker: 'Betfair/placePlayerBetNewBookMaker',
    lottery: 'Betfair/placePlayerBetNewBookMaker',
    multiHorse: 'Betfair/placePlayerBetNewforMultiHorse',
    lineLadder: 'PlaceBetLineSession/PlaceLineSessionBetForPlayer',
    premiumsports: 'PlaceBet/processPlaceBet',
    artemisBookmaker: 'bets/processPlaceBet',
    artemisFancy: 'bets/processFancyPlaceBet'
  }
})
export const mutations = {
  set_matchIds(state, data) {
    state.matchIds = data
  },
  set_placeBetJSON(
    state,
    {
      market,
      runner,
      runs,
      odds,
      position,
      stake,
      type,
      winning,
      accessToken,
      userName
    }
  ) {
    const baseBetProps = {
      userName,
      odds: Number(odds),
      position,
      stake,
      type: type.toUpperCase(),
      updatedodds: winning / 100,
      marketId: market.betfairId,
      selectionId: runner.betfairId,
      selectionName: runner.name,
      marketName: market.name,
      handicap: runner.handicap,
      error: false
    }

    let output = {}

    switch (state.bettingType) {
      case 'default':
        output = {
          ...baseBetProps,
          betType: 'odds',
          eventId: market.parentCategory.betfairId,
          event: market.eventName,
          book: market.runners
        }
        break

      case 'fancy':
        output = {
          ...baseBetProps,
          betType: 'fancy-market-custom',
          custom: true,
          runs,
          updatedodds: odds - 1,
          event: runner.eventName
        }
        break

      case 'bookmaker':
      case 'lottery':
      case 'multiHorse':
        output = {
          ...baseBetProps,
          betType: 'odds',
          refOdds: odds,
          blank: '',
          placeBetCalcType: market.placeBetCalcType || 1,
          isJodiMarket: market.isJodiMarket || false,
          eventId: market.parentCategory.betfairId,
          event: market.eventName,
          book: market.runners
        }
        break

      case 'artemisBookmaker':
        output = {
          odds,
          stake,
          marketUniqueId: market._id,
          selectionId: runner.id,
          type: type.toUpperCase()
        }
        break

      case 'artemisFancy':
        output = {
          runs,
          odds,
          stake,
          marketUniqueId: runner._id,
          selectionId: runner.selectionId,
          type: type.toUpperCase()
        }
        break

      case 'lineLadder':
        output = {
          _accessToken: accessToken,
          betType: state.bettingType,
          custom: true,
          error: false,
          marketId: market.betfairId,
          runs: Number(odds) - 0.5,
          odds: 2,
          selectionId: runner.betfairId,
          selectionName: runner.name,
          stake,
          type: type.toUpperCase(),
          updatedodds: winning / 100,
          userName
        }
        break

      case 'premiumsports':
        output = {
          odds,
          stake,
          marketUniqueId: market._id,
          selectionId: runner.id
        }
        break

      default:
        break
    }

    if (
      !['premiumsports', 'artemisBookmaker', 'artemisFancy'].includes(
        state.bettingType
      )
    ) {
      output[this.type === 'lay' ? 'liability' : 'profit'] = winning
    }

    state.placeBetJSON = output
  },
  openPopover(
    state,
    {
      market,
      runner,
      allRunners,
      odds,
      oddsIndex,
      type,
      isCashOut,
      bettingType,
      runs,
      stake
    }
  ) {
    if (odds !== undefined) {
      // console.log({
      //   market,
      //   runner,
      //   allRunners,
      //   odds,
      //   oddsIndex,
      //   type,
      //   bettingType,
      //   runs
      // })
      state.market = market
      state.runner = runner
      state.allRunners = allRunners
      state.odds = odds
      state.isCashOut = isCashOut || false
      state.oddsIndex = oddsIndex
      state.runs = runs
      state.type = type
      state.bettingType = bettingType
      state.stake = stake
    } else {
      let error
      if (odds !== undefined) {
        error = 'Odds are not there'
      }
      state.error = error
      this.commit('snackbar/open', {
        text: state.error,
        color: 'error'
      })
    }
    this.commit('notificationMarquee/set_isBottomDrawerOpen', false)
  },
  set_error(state, data) {
    console.log(data)
    let error

    if (data?.error === 'MIN_BET_STAKE_REQUIRED') {
      error = 'Please increase bet amount'
    } else if (data?.error === 'accessDenied') {
      error = 'Access Denied'
    } else if (data?.error === 'no match found 1') {
      error = 'Market is suspended'
    } else if (
      [
        'No match found',
        'no match found 1',
        'no match found 2',
        'no match found 3',
        'no match found 4'
      ].includes(data?.error)
    ) {
      error = 'Market is Closed'
    } else if (data?.error === 'Insufficient credit limit') {
      error = 'Insufficient credit limit'
    } else if (data?.error === 'Exceeded the profit limit') {
      error = 'Profit Limit Exceeded'
    } else if (data?.error === 'Exceeded the Max Market limit') {
      error = 'Max win limit exceeded'
    } else if (data?.status === 'UNMATCHED') {
      error = data?.message
    } else if (data?.error === 'Previous Bet In Process') {
      error = data?.error
    } else if (data?.error?.message === 'limitExceeded') {
      error = 'Max Bet Limit Reached'
    } else if (data?.error?.message === 'Your account has been suspended.') {
      error = 'Your account has been suspended'
    } else if (data?.error?.message === 'Betting locked ') {
      error = 'Betting locked Please contact your upline'
    } else if (data?.message === 'timeout of 600100ms exceeded') {
      error = 'Error Placing Bet. Please Try again.'
    } else if (data?.message === 'Network Error') {
      error =
        'Unable to place bet due to bad network. Please refresh your page for better experience.'
    } else if (
      data?.error?.body === 'Unable to place bet as rate is greater than 3'
    ) {
      error = 'Unable to place bet as rate is greater than 3'
    } else if (data?.error === 'no horse found') {
      error = 'Selected horse is suspended'
    } else if (data?.error?.body === 'Odds have changed.') {
      error = 'Odds have changed.'
    } else if (data?.error === 'Odds Lay has changed') {
      error = 'Odds Lay has changed'
    } else if (data?.error === 'Odds Back has changed') {
      error = 'Odds Back has changed'
    } else if (
      ['Market Suspended', 'Market is Suspended'].includes(data?.error)
    ) {
      error = 'Market Suspended'
    } else if (data?.error === 'Ball Running') {
      error = 'Ball Running'
    } else if (data?.error?.status === 500) {
      error = 'Market is Suspended'
    } else if (data?.error?.error?.message) {
      error =
        data.error.error.message === 'UNABLE TO CALL BETFAIR'
          ? 'Error - 10, Please try again'
          : data.error.error.message
    } else if (data?.error?.body?.message) {
      error = data.error.body.message
    } else if (data?.error?.message) {
      error = data.error.message
    } else if (data?.error?.body) {
      error = data.error.body
    } else if (data?.status === 'PARTIALLY_MATCHED') {
      error = data?.message
    } else if (data?.message) {
      error = data.message
    } else if (data?.errors?.[0]?.msg) {
      error = data.errors[0].msg
    } else if (data?.data?.success === false && data?.data?.errorMsg) {
      error = data.data.errorMsg
    } else {
      error = 'Error while placing Bet'
    }

    state.error = error
  },
  reset_error(state) {
    state.error = ''
  },
  reset_success(state) {
    state.success = ''
  },
  reset_warning(state) {
    state.warning = ''
  },
  set_success(state, data) {
    state.success = data
  },
  set_warning(state, data) {
    state.warning = data
  },
  set_blank(state, data) {
    state.blank = data
  },

  set_loading(state, data) {
    state.loading = data
  },
  reset_popover(state) {
    state.market = []
    state.runner = []
    state.allRunners = []
    state.odds = 0
    state.oddsIndex = 0
    state.runs = 0
    state.type = ''
    state.bettingType = ''
  }
}

export const actions = {
  async execute({ commit, state }, data) {
    let accessToken, userName, dataPlaceBet
    if (this.$auth.loggedIn) {
      accessToken = this.$auth.getToken('customLocal')
      userName = this.$auth.user.username
    }
    let baseurl = process.env.SPORTSBOOK_V2_API_URL
    commit('set_placeBetJSON', { ...data, accessToken, userName })
    dataPlaceBet = {
      // _accessToken: accessToken,
      body: ['Horse Racing', 'Greyhound Racing'].includes(
        state?.market?.eventType?.name
      )
        ? [{ ...state.placeBetJSON, game: state?.market?.eventType?.name }]
        : [state.placeBetJSON]
    }
    const urls = {
      lineLadder: process.env.LINE_SESSSION_URL,
      premiumsports: process.env.UOF_SPORTSRADAR_SERVICE_URL,
      artemisBookmaker: process.env.PREMIUM_BOOKMAKER_URL,
      artemisFancy: process.env.PREMIUM_BOOKMAKER_URL
    }

    if (urls[state.bettingType]) {
      baseurl = urls[state.bettingType]
      dataPlaceBet = state.placeBetJSON
    }

    try {
      commit('set_loading', true)
      commit('reset_error')
      commit('reset_success')
      commit('reset_warning')

      const response = await this.$axios({
        method: 'post',
        baseURL: baseurl,
        url: state.urlMap[state.bettingType],
        data: dataPlaceBet
      })

      commit('set_loading', false)

      const betType = state.bettingType
      const resData = response?.data
      const betData = resData?.data?.[0]
      const betStatus = betData?.status
      const message = resData?.message
      const messageStatus = message?.status
      const isSuccess = resData?.data?.isSuccess
      const resValue = resData?.value
      const statusCode = resData?.data?.statusCode
      const successStatus = resData?.success

      if (
        resValue &&
        !['premiumsports', 'artemisBookmaker', 'artemisFancy'].includes(betType)
      ) {
        if (!isEmpty(betType) && betType !== 'lineLadder') {
          if (betStatus === 'EXECUTION_COMPLETE') {
            if (message && messageStatus === 'MATCHED') {
              commit('set_success', message)
            } else if (message && messageStatus === 'PARTIALLY_MATCHED') {
              commit('set_warning', message)
            } else if (message && messageStatus === 'UNMATCHED') {
              commit('set_success', message)
              commit('set_blank', true)
            } else {
              commit('set_success', 'Bet Placed successfully!')
            }
          } else if (betStatus === 'EXECUTABLE') {
            if (message && messageStatus === 'UNMATCHED') {
              commit('set_success', message)
              commit('set_blank', true)
            } else if (message && messageStatus === 'PARTIALLY_MATCHED') {
              commit('set_warning', message)
            } else if (message && messageStatus !== 'UNMATCHED') {
              commit('set_success', message)
            } else {
              commit('set_success', 'Bet Unmatched!')
            }
          } else if (message && messageStatus === 'MATCHED') {
            commit('set_success', message)
          }
        } else if (!isEmpty(betType) && betType === 'lineLadder') {
          if (isSuccess) {
            if (resData?.data?.status === 'UNMATCHED') {
              commit('set_error', resData?.data)
            } else {
              commit('set_success', resData?.data?.message)
            }
          } else {
            commit('set_error', resData?.data)
          }
        } else if (isEmpty(betType)) {
          if (!isSuccess) {
            commit('set_error', resData?.data)
          }
        }
      } else if (successStatus && betType === 'premiumsports') {
        commit('set_success', resData?.msg)
      } else {
        const isInvalidBetType =
          !resValue &&
          !['premiumsports', 'artemisBookmaker', 'artemisFancy'].includes(
            betType
          )
        const isPremiumsportsError =
          !successStatus && betType === 'premiumsports'
        const isBookmakerError =
          statusCode !== 200 &&
          !resData?.data?.success &&
          ['artemisBookmaker', 'artemisFancy'].includes(betType)

        if (isInvalidBetType || isPremiumsportsError || isBookmakerError) {
          commit('set_error', resData)
        }
      }
    } catch (error) {
      commit('set_loading', false)
      if (error?.response?.data === 'upstream request timeout') {
        commit('set_error', { message: 'Error while placing bet' })
      } else {
        commit('set_error', error?.response?.data || error)
      }
    } finally {
      commit('set_loading', false)
    }
  }
}
