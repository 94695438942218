import encryptor from '~/plugins/encryption.js'
// import groupBy from 'lodash/groupBy'
export const state = () => ({
  liveData: {
    tab: 'Live',
    icon: 'mdi-motion-play-outline',
    fixtures: []
  },
  upcomingData: {
    tab: 'Upcoming',
    icon: 'mdi-motion-play-outline',
    fixtures: []
  },
  leagues: false,
  isEmpty: false,
  liveMatchIds: [],
  upcomingMatchIds: [],
  loading: false,
  error: ''
})

export const mutations = {
  set_isEmpty(state, data) {
    state.isEmpty = data
  },
  set_matchIds(state, { data = [], upComing = false }) {
    if (data?.length) {
      if (!upComing) state.liveMatchIds = data
      else state.upcomingMatchIds = data
      this.commit('placeBet/set_matchIds', data)
    }
  },
  set_data(state, { matchData = [], upComing = false }) {
    if (!upComing) state.liveData.fixtures = matchData
    else state.upcomingData.fixtures = matchData
  },
  set_leagues(state, value) {
    state.leagues = value
      ? { tab: 'Leagues', icon: 'mdi-format-list-checks' }
      : false
  },
  set_error(state, data) {
    state.error = data
    this.commit('snackbar/open', {
      text: state.error,
      color: 'error'
    })
  },
  set_loading(state, data) {
    state.loading = data
  }
}

export const actions = {
  async getMarketsById(
    { commit, state },
    { game, competitionId, loading = true, socketData, upComing = false }
  ) {
    commit('set_matchIds', { data: [], upComing })
    let userId
    try {
      commit('set_loading', loading)
      if (loading) {
        commit('set_data', { matchData: [], upComing })
      }

      let matchData
      if (socketData) {
        matchData = socketData
      } else {
        const { data: responseData } = await this.$axios({
          method: 'post',
          baseURL: process.env.SPORTSBOOK_API_URL_MARKET,
          url: 'category/newGetMarketIds',
          data: {
            game,
            competitionId,
            _id: userId,
            _accessToken: this.$auth.getToken('customLocal'),
            accessLevel: 'Player',
            isFancy: false,
            upComing
          }
        })
        matchData = responseData ? responseData.data : undefined

        if (responseData && responseData.data && !responseData.data.length) {
          const totalRecords =
            responseData.data?.length +
            (!upComing
              ? state.liveData?.fixtures?.length
              : state.upcomingData?.fixtures?.length)
          commit('set_isEmpty', totalRecords === 0)
          commit('set_data', { matchData: [], upComing })
        }
      }

      if (matchData && matchData.length > 0 && Array.isArray(matchData)) {
        commit('set_isEmpty', false)
        const matchIds = []
        matchData.forEach((market) => {
          if (market?.betfairId) {
            matchIds.push(market.betfairId)
          }
        })

        if (matchIds.length > 0) {
          this.commit('socketRate/SET_IDS', matchIds)
          this.commit('socketBookmaker/SET_IDS', matchIds)
        }

        commit('set_matchIds', { data: matchIds, upComing })
        if (!loading) {
          commit('set_data', { matchData: [], upComing })
        }

        const mapped = matchData.map((v) => {
          if (v) {
            v.bookMakerCustom =
              v && v?.betfairId && v?.betfairId?.includes('#bookmaker')
            if (
              (v.inPlayStatus && v.inPlayStatus === 'Open') ||
              (v.markets &&
                v.markets.length > 0 &&
                v.markets[0].inPlayStatus === 'Open')
            ) {
              v.status = 'live'
            } else {
              v.status = 'upcoming'
            }
          }
          return v
        })

        const favoritesMatchIds = await JSON.parse(
          encryptor.decrypt(localStorage.getItem('set_Favorites_MatchIds'))
        )
        for (let i = 0; i < mapped.length; i++) {
          mapped[i].isFavourite = favoritesMatchIds?.includes(
            mapped[i].betfairId
          )
        }

        commit('set_data', { matchData: mapped, upComing })
      } else if (!matchData?.length) {
        const totalRecords =
          matchData?.length +
          (!upComing
            ? state.liveData?.fixtures?.length
            : state.upcomingData?.fixtures?.length)
        commit('set_isEmpty', totalRecords === 0)
        commit('set_data', { matchData: [], upComing })
      } else if (!matchData) {
        commit('set_error', 'Error getting matches')
      }
      commit('set_loading', false)
    } catch (error) {
      commit('set_error', error.message || 'Error fetching data')
      commit('set_loading', false)
    }
  }
}
