export const state = () => ({
  data: [],
  loading: false,
  error: false
})

export const mutations = {
  set_data(state, data) {
    state.data = data
  },
  set_error(state, data) {
    console.log('set_error', data)
    state.error = data
  },
  set_loading(state, data) {
    state.loading = data
  }
}

export const actions = {
  async get({ commit }, { game = 'Cricket' }) {
    try {
      commit('set_data', '')
      let _accessToken

      if (this.$auth.loggedIn) {
        _accessToken = this.$auth.getToken('customLocal')
      }
      commit('set_loading', true)

      const response = await this.$axios({
        method: 'post',
        baseURL: process.env.SPORTSBOOK_API_URL_MARKET,
        url: 'category/newGetMarketIds',
        data: {
          game,
          _accessToken,
          accessLevel: 'Player',
          isFancy: false,
          recent: true
        }
      })
      commit('set_loading', false)
      commit('set_data', response.data.data)
    } catch (error) {
      commit('set_error', error)
      commit('set_loading', false)
    }
  }
}
